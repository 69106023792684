<div *ngFor="let item of summaries; let i = index">
  <h4 class="fd-title fd-title--h4" id="fd-wizard-product-type">{{ i + 1 }}. {{ item.title | translate }}</h4>
  <fd-layout-grid style="margin-bottom: 2rem">
    <div fdLayoutGridRow>
      <div [fdLayoutGridCol]="4" [colMd]="2" [colLg]="2" [colXl]="2">
        <label fd-form-label style="float: right">{{ item.label | translate }}</label>
      </div>
      <div [fdLayoutGridCol]="4" [colMd]="5" [colLg]="5" [colXl]="5">
        <label fd-form-label style="color: black" *ngFor="let value of item.values">{{ value }}</label>
      </div>
      <div [fdLayoutGridCol]="1" [colMd]="5" [colLg]="5" [colXl]="5">
        <a
          fd-link
          (click)="onEdit(i)"
          aria-labelledby="fd-wizard-product-type"
          [title]="'BUTTONS.EDIT' | translate"
          *ngIf="canEditStep(item)"
          >{{ "BUTTONS.EDIT" | translate }}</a
        >
      </div>
    </div>
  </fd-layout-grid>
</div>
