<fd-dialog *ngIf="data">
  <fd-dialog-header>
    <ng-template fdkTemplate="header">
      <div fd-bar-left>
        <fd-bar-element>
          <h1 fd-title>User details for {{ data.username }}</h1>
        </fd-bar-element>
      </div>
      <div fd-bar-middle>
        <fd-bar-element [fullWidth]="true">
          <fd-input-group
            glyph="search"
            placement="after"
            placeholder="{{ 'BUTTONS.FILTER' | translate }}"
            iconTitle="{{ 'BUTTONS.FILTER' | translate }}"
            [button]="true"
            [showFocus]="false"
            [buttonFocusable]="false"
            [(ngModel)]="searchText"
            (addOnButtonClicked)="onSearch()"
          >
          </fd-input-group>
        </fd-bar-element>
      </div>
      <div fd-bar-right>
        <fd-bar-element>
          <fd-object-identifier description="Alias: {{ data.usernameAlias }}"> </fd-object-identifier>
        </fd-bar-element>
      </div>
    </ng-template>
  </fd-dialog-header>

  <fd-dialog-body>
    <fd-panel *ngIf="data.address" [expanded]="autoExpand">
      <h5 fd-panel-title>Address</h5>
      <div fd-panel-content>
        <table fd-table>
          <tbody fd-table-body>
            <tr fd-table-row *ngFor="let item of data.address | keyvalue : returnZero">
              <td fd-table-cell>{{ item.key }}</td>
              <td fd-table-cell>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </fd-panel>
    <fd-panel *ngIf="data.company" [expanded]="autoExpand">
      <h5 fd-panel-title>Company</h5>
      <div fd-panel-content>
        <table fd-table>
          <tbody fd-table-body>
            <tr fd-table-row *ngFor="let item of data.company | keyvalue : returnZero">
              <td fd-table-cell>{{ item.key }}</td>
              <td fd-table-cell>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </fd-panel>
    <fd-panel *ngIf="data.snc" [expanded]="autoExpand">
      <h5 fd-panel-title>SNC</h5>
      <div fd-panel-content>
        <table fd-table>
          <tbody fd-table-body>
            <tr fd-table-row *ngFor="let item of data.snc | keyvalue : returnZero">
              <td fd-table-cell>{{ item.key }}</td>
              <td fd-table-cell>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </fd-panel>
    <fd-panel *ngIf="data.techUser" [expanded]="autoExpand">
      <h5 fd-panel-title>Tech User</h5>
      <div fd-panel-content>
        <table fd-table>
          <tbody fd-table-body>
            <tr fd-table-row *ngFor="let item of data.techUser | keyvalue : returnZero">
              <td fd-table-cell>{{ item.key }}</td>
              <td fd-table-cell>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </fd-panel>
    <fd-panel *ngIf="rolesRows.length" [expanded]="autoExpand">
      <h5 fd-panel-title>Roles</h5>
      <div fd-panel-content>
        <fdp-table
          [dataSource]="availableSapRolesDataSource"
          [pageScrolling]="true"
          [pageSize]="20"
          [forceCheckedAllState]="true"
          [pageScrollingThreshold]="20"
          [loadPagesBefore]="true"
          bodyHeight="20rem"
          emptyTableMessage="{{ 'MESSAGES.NO_DATA' | translate }}"
        >
          <fdp-table-toolbar [title]="'LABELS.ROLES' | translate" [hideSearchInput]="true" [shouldOverflow]="true">
            <fdp-table-toolbar-actions>
              <marbles-copy-clipboard-button
                [data]="availableSapRolesDataSource.getClipboardDataTable"
                [htmlTable]="true"
              ></marbles-copy-clipboard-button>
            </fdp-table-toolbar-actions>
          </fdp-table-toolbar>
          <fdp-column [name]="item" [key]="item" [label]="item" *ngFor="let item of rolesColumns"> </fdp-column>
        </fdp-table>
      </div>
    </fd-panel>
    <fd-panel *ngIf="data.adminData" [expanded]="autoExpand">
      <h5 fd-panel-title>Admin Data</h5>
      <div fd-panel-content>
        <table fd-table>
          <tbody fd-table-body>
            <tr fd-table-row *ngFor="let item of data.adminData | keyvalue : returnZero">
              <td fd-table-cell>{{ item.key }}</td>
              <td fd-table-cell>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </fd-panel>
    <fd-panel *ngIf="data.lastModified" [expanded]="autoExpand">
      <h5 fd-panel-title>Last Modified</h5>
      <div fd-panel-content>
        <table fd-table>
          <tbody fd-table-body>
            <tr fd-table-row *ngFor="let item of data.lastModified | keyvalue : returnZero">
              <td fd-table-cell>{{ item.key }}</td>
              <td fd-table-cell>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </fd-panel>
  </fd-dialog-body>

  <fd-dialog-footer>
    <fd-button-bar
      [label]="'BUTTONS.CLOSE' | translate"
      fdType="emphasized"
      (click)="dialogRef.close('Close')"
      ariaLabel="Interesting Emphasized"
    >
    </fd-button-bar>
  </fd-dialog-footer>
</fd-dialog>
