import { TableComponent, TableDataSource } from "@fundamental-ngx/platform";
import { IClipboardTableData } from "../layout/copy-clipboard-button/copy-clipboard-button.component";
import { MarblesTableDataProvider } from "./marbles-table-data-provider";

export class MarblesTableDataSource<T extends Record<string, any>> extends TableDataSource<T> {
  override dataProvider!: MarblesTableDataProvider<T>;
  private table?: TableComponent;

  /**
   * Get the table items an IClipboardTableData data object
   *
   * @returns IClipboardTableData
   */
  public getClipboardDataTable = (): IClipboardTableData => {
    const items = this.dataProvider.originalItems || [];
    const headers = items.length ? Object.keys(items[0]) : [];
    const rows = items.map((row) => Object.values(row));
    return { headers, rows };
  };

  /**
   * Get the table items a string array
   *
   * @returns string[]
   */
  public getClipboardDataList = (): string[] => {
    const items = this.dataProvider.originalItems || [];
    const rows = items.map((row) => JSON.stringify(row));
    return rows;
  };

  /**
   * Get the table items as a string
   *
   * @returns string
   */
  public getClipboardDataText = () => {
    const items = this.dataProvider.originalItems || [];
    const rows = items.map((row) => Object.values(row));
    return JSON.stringify(rows);
  };

  /**
   * Update the data provider and the table
   * @param {any} table:TableComponent
   * @param {any} data:T[]
   * @returns {any}
   */
  public updateTableData(table: TableComponent, data: T[]) {
    this.dataProvider.update(data);
    table?._dataSourceDirective.initializeDataSource();
  }

  /**
   * Update the data provider and reinitilize the tabele
   * @param {any} data:T[]
   * @returns {any}
   */
  public updateData(data: T[]) {
    this.dataProvider.update(data);
    this.table?._dataSourceDirective.initializeDataSource();
  }

  /**
   * Set the table component refenrece]
   * @param {any} table:TableComponent
   * @returns {any}
   */
  public setTableRef(table: TableComponent) {
    this.table = table;
  }

  /**
   * Reload the table by re-initializing the data source
   * @returns void
   */
  public reloadData() {
    this.dataProvider.update([]);
    this.table?._dataSourceDirective.initializeDataSource();
  }

  /**
   * Check if the data provider has comepleteg loading is loaded
   * @returns boolean
   */
  get loaded(): boolean {
    return !this.dataProvider.loading.getValue();
  }
}
