import { Injectable, OnDestroy } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, Observable, Subject, first, lastValueFrom, map, takeUntil, throwError } from "rxjs";
import {
  IAuthCredential,
  IAuthToken,
  IAuthTokenData,
  IAuthTokenUserData,
  ILoginOperationResult,
  IRefreshOperationResult,
} from "../interfaces/auth.model";
import { API_BASE_URL, ApiClientService } from "./api-client.service";
import { AuthTokenService } from "./auth-token.service";
import { ErrorManagementService } from "./error-management.service";

const ENDPOINT = {
  LOGIN: API_BASE_URL + "/auth/token/obtain/",
  REFRESH: API_BASE_URL + "/auth/token/refresh/",
  VERIFY: API_BASE_URL + "/auth/token/verify/",
};
@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  /**
   * if ngUnsubscribe$ emits a value (null) --> terminate all running subscriptions inside the service
   */
  private ngUnsubscribe$: Subject<null> = new Subject();

  /**
   * during the runtime we save the auth data (therefore token) inside RAM
   */
  private _authorizationData: BehaviorSubject<IAuthToken> = new BehaviorSubject<IAuthToken>({
    loggedIn: false,
  });

  private decodedToken: IAuthTokenData | null = null;

  public userData$ = new BehaviorSubject<IAuthTokenUserData>({
    user_id: "",
    companies: [],
    isFrontendAdmin: false,
  });

  constructor(
    private apiClientService: ApiClientService,
    public tokenService: AuthTokenService,
    public jwtHelperService: JwtHelperService,
    public errorManagementService: ErrorManagementService,
  ) {
    this.loadAuthDataFromStorage();

    this.authorizationData$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((authData) => {
      this.tokenService.saveAuthData(authData);
      this.decodeTokenData(authData);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
  }

  private loadAuthDataFromStorage() {
    const authData = this.tokenService.loadAuthData();
    if (authData) {
      this._authorizationData.next(authData);
    }
  }

  public get authorizationData$() {
    return this._authorizationData.asObservable();
  }

  public async login(authData: IAuthCredential) {
    let loginSucceded = false;
    const loginResponse = await lastValueFrom(
      this.apiClientService.post<ILoginOperationResult>(ENDPOINT.LOGIN, authData).pipe(
        first(),
        map((loginReponseData) => {
          let authData: IAuthToken = {
            loggedIn: false,
          };

          if (!loginReponseData.access || !loginReponseData.refresh) {
            return authData;
          }

          authData = {
            access_token: loginReponseData.access,
            refresh_token: loginReponseData.refresh,
            loggedIn: true,
          };
          return authData;
        }),
      ),
    ).catch((error) => {
      this.errorManagementService.handleException({
        errorCode: "login_failed_error",
        generalErrorName: "login_error",
      });
    });
    if (loginResponse) {
      loginSucceded = true;
      this._authorizationData.next(loginResponse);
    }
    return loginSucceded;
  }

  public getToken() {
    return this._authorizationData.getValue().access_token;
  }

  /**
   * Retrieve new access_token with valid refresh_token
   */
  public refreshToken(): Observable<string> {
    const currentTokens = this._authorizationData.getValue();
    if (currentTokens.refresh_token && currentTokens.loggedIn) {
      return this.apiClientService
        .post<IRefreshOperationResult>(ENDPOINT.REFRESH, {
          refresh: currentTokens.refresh_token,
        })
        .pipe(
          map((refreshed_data) => {
            if (refreshed_data.access) {
              currentTokens.access_token = refreshed_data.access;
              this._authorizationData.next(currentTokens);
            }
            return refreshed_data.access;
          }),
        );
    }
    // Force logout if no tokens
    return throwError(() => new Error("No token found"));
  }

  public logout() {
    this.tokenService.deleteAuthData();
    /**
     * emit activeLogoutOperation: true to allow the BaseStoreService to fetch the logout process and cleanup resources
     */
    this._authorizationData.next({
      loggedIn: false,
      activeLogoutOperation: true,
    });
    this._authorizationData.next({
      loggedIn: false,
    });
  }

  public get isAuthenticated() {
    return this._authorizationData.getValue().loggedIn;
  }

  /**
   * Get the valid access token or return undefined if not valid
   */
  public getAccessToken() {
    const authData = this._authorizationData.getValue();
    if (authData.loggedIn && authData.access_token) {
      return this.jwtHelperService.isTokenExpired(authData.access_token) ? undefined : authData.access_token;
    }
    return undefined;
  }

  public decodeTokenData(authData: IAuthToken) {
    if (!authData.access_token) {
      return;
    }
    this.decodedToken = this.jwtHelperService.decodeToken<IAuthTokenData>(authData.access_token);
    if (this.decodedToken) {
      this.userData$.next({
        user_id: this.decodedToken.user_id,
        companies: this.decodedToken.companies,
        isFrontendAdmin: this.decodedToken.isFrontendAdmin,
      });
    }
  }
}
