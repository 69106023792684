import { Injectable } from "@angular/core";

export interface LaunchpadConfig {
  sidebarCollapsed: boolean;
}

@Injectable({
  providedIn: "root",
})
export class LaunchpadService {
  public readonly settings: LaunchpadConfig = {
    sidebarCollapsed: true,
  };

  constructor() {}

  /**
   * TOggle the side navigation to collapse or expand
   * @param {boolean} collapse
   * @returns void
   */
  toggleVerticalNavigationCollapse() {
    this.settings.sidebarCollapsed = !this.settings.sidebarCollapsed;
  }
}
