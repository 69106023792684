import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { ContentDensityModule } from "@fundamental-ngx/core";
import { ThemingModule, ThemingService } from "@fundamental-ngx/core/theming";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ENVIRONMENT_INJECT_TOKEN } from "./constants/marbles.constants";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { LayoutModule } from "./layout/layout.module";
import { NotificationManagerComponent } from "./layout/notification-manager/notification-manager.component";
import { LanguageService } from "./services/language.service";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: ENVIRONMENT_INJECT_TOKEN,
      useValue: environment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: JWT_OPTIONS,
      useValue: {},
    },
    JwtHelperService,
    LanguageService,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ContentDensityModule.forRoot(),
    ThemingModule.withConfig({ defaultTheme: "sap_horizon" }),
    LayoutModule,
    BrowserAnimationsModule,
    NotificationManagerComponent,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(themingService: ThemingService) {
    themingService.init();
  }
}
