import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from "@angular/core";
import { IconFont, WizardService, WizardStepComponent, WizardStepStatus } from "@fundamental-ngx/core";
import { Observable, Subject, map, takeUntil } from "rxjs";
import { MarbleWizard } from "src/app/shared/marbles-wizard";
import {
  ProcessWizardSummaryItem,
  ProcessWizardSummaryStepConfig,
} from "../process-wizard-step-summary/process-wizard-step-summary.component";
import { WizardActionItem } from "../wizard-action-list/wizard-action-list.component";

export type ProcessWizardStep = {
  status: WizardStepStatus;
  label: string;
  glyph?: string;
  glyphFont?: IconFont;
  messageStrip?: string;
  isSummary?: boolean;
  template?: TemplateRef<any>;
  canEditStep?: (visited: boolean, completed: boolean) => boolean | Promise<boolean>;
  summary?: ProcessWizardSummaryStepConfig;
  enableNextStep: () => boolean;
};

export interface ProcessWizardConfiguration {
  steps: ProcessWizardStep[];
  actions: WizardActionItem[];
  processValid?: () => boolean;
  submitLabel?: string;
}

export interface IProcessWizard {
  config: Observable<ProcessWizardConfiguration>;
  summaries: ProcessWizardSummaryItem[];
}

export type ProcessCompleteCallback = () => void;

@Component({
  selector: "app-process-wizard",
  templateUrl: "./process-wizard.component.html",
  styleUrls: ["./process-wizard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WizardService],
})
export class ProcessWizardComponent extends MarbleWizard implements OnInit, OnDestroy {
  /** @hidden */
  @ViewChildren(WizardStepComponent)
  wizardStepComponents!: QueryList<WizardStepComponent>;

  @Input()
  config!: Observable<ProcessWizardConfiguration>;

  steps: ProcessWizardStep[] = [];

  @Input()
  wizardFinished!: TemplateRef<any> | null;

  actions: WizardActionItem[] = [];

  summaries: ProcessWizardSummaryItem[] = [];

  @Input()
  busyTitle?: string;

  private ngUnsubscribe$ = new Subject<null>();

  @Output()
  submit = new EventEmitter<ProcessCompleteCallback>();

  public summaryStep: ProcessWizardStep = {
    status: "upcoming" as WizardStepStatus,
    label: "MESSAGES.SUMMARY",
    isSummary: true,
    glyph: "approvals",
    enableNextStep: () => false,
  };

  public sendingData = false;
  public processFinished = false;

  private inReviewMode = false;
  public submitLabel = "BUTTONS.SUBMIT";

  constructor(private wizardService: WizardService, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.config
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        map((config) => {
          config.steps.push({
            ...this.summaryStep,
            enableNextStep: config.processValid ?? this.summaryStep.enableNextStep,
          });
          return config;
        }),
      )
      .subscribe((config) => {
        this.steps = config.steps;
        this.actions = config.actions;
        if (config.submitLabel) {
          this.submitLabel = config.submitLabel;
        }
        this.changeDetectorRef.detectChanges();
      });
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
  }

  public reset() {
    this.sendingData = false;
    this.processFinished = false;
    this.goToStep(1);
  }

  public isSummaryStep(index: number) {
    return index + 1 === this.steps.length;
  }

  public isFinalStep(index: number) {
    return this.inReviewMode || index === this.steps.length - 2;
  }

  public statusChanged(stepNumber: number, event: WizardStepStatus): void {
    if (event === "current") {
      this.goToStep(stepNumber);
    }
  }

  // Handle focus on key press
  /** @hidden */
  public handleFocus(event: KeyboardEvent, index: number): void {
    this.wizardService.progressBarKeyHandler(event, this.wizardStepComponents, index);
  }

  public get shouldDisplayWizard() {
    return this.steps.length > 1;
  }

  public prepareSummary() {
    this.inReviewMode = true;
    this.generateSummary();
    this.goToStep(this.steps.length);
  }

  public submitData() {
    this.sendingData = true;
    this.submit.emit(this.complete.bind(this));
  }

  public setSummaries(summaries: ProcessWizardSummaryItem[]) {
    this.summaries = summaries;
  }

  private generateSummary() {
    const summaries: ProcessWizardSummaryItem[] = this.steps
      .filter((step) => !step.isSummary && step.summary)
      .map((step) => ({
        title: step.label,
        label: step.summary!.label,
        values: step.summary!.values(),
        canModifyStep: step.summary!.canModifyStep,
      }));
    this.setSummaries(summaries);
  }

  public complete() {
    this.sendingData = false;
    this.processFinished = true;
    this.inReviewMode = false;
    this.changeDetectorRef.markForCheck();
  }

  public stepValidator(item: ProcessWizardStep) {
    return item.canEditStep ? item.canEditStep : () => true;
  }
}
