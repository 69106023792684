import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface IErrorEmission {
  generalErrorName: string;
  errorCode: string;
  generalErrorMessage: string;
  detailErrorMessage: string;
  errorTracingId?: string;
}

export interface IDefaultErrorResponse {
  generalErrorName: string;
  errorCode: string;
  additionalData?: any;
  errorTracingId?: string;
}

export const CTranslatedErrorGeneralMessage: Map<string, string> = new Map<string, string>([
  ["user_error", "ERRORS.GENERAL.USER_ERROR"],
  ["sap_system_error", "ERRORS.GENERAL.SAP_SYSTEM_ERROR"],
  ["companies_error", "ERRORS.GENERAL.COMPANIES_ERROR"],
  ["connectivity_error", "ERRORS.GENERAL.CONNECTIVITY_ERROR"],
  ["internal_server_error", "ERRORS.GENERAL.INTERNAL_SERVER_ERROR"],
  ["user_input_error", "ERRORS.GENERAL.USER_INPUT_ERROR"],
  ["permission_error", "ERRORS.GENERAL.PERMISSION_ERROR"],
  ["login_error", "ERRORS.GENERAL.LOGIN_ERROR"],
]);

export const CTranslatedErrorDetailMessage: Map<string, string> = new Map<string, string>([
  ["sap_system_functionmodule_error", "ERRORS.DETAIL.USER_ERROR"],
  ["sap_system_not_reachable", "ERRORS.DETAIL.SAP_SYSTEM_NOT_REACHABLE"],
  ["sap_system_auth_failed", "ERRORS.DETAIL.SAP_SYSTEM_AUTH_FAILED"],
  ["credential_pull_error", "ERRORS.DETAIL.CREDENTIAL_PULL_ERROR"],
  ["wrong_request_data", "ERRORS.DETAIL.WRONG_REQUEST_DATA"],
  ["not_found", "ERRORS.DETAIL.NOT_FOUND"],
  ["permission_denied", "ERRORS.DETAIL.PERMISSION_DENIED"],
  ["invalid_token", "ERRORS.DETAIL.INVALID_TOKEN"],
  ["invalid_signature", "ERRORS.DETAIL.INVALID_SIGNATURE"],
  ["token_expired", "ERRORS.DETAIL.TOKEN_EXPIRED"],
  ["rate_limit_exceeded", "ERRORS.DETAIL.RATE_LIMIT_EXCEEDED"],
  ["unsupported_media_type", "ERRORS.DETAIL.UNSUPPORTED_MEDIA_TYPE"],
  ["bad_request", "ERRORS.DETAIL.BAD_REQUEST"],
  ["unauthorized", "ERRORS.DETAIL.UNAUTHORIZED"],
  ["forbidden", "ERRORS.DETAIL.FORBIDDEN"],
  ["not_acceptable", "ERRORS.DETAIL.NOT_ACCEPTABLE"],
  ["conflict", "ERRORS.DETAIL.CONFLICT"],
  ["internal_server_error", "ERRORS.DETAIL.INTERNAL_SERVER_ERROR"],
  ["company_id_forbidden", "ERRORS.DETAIL.SAP_SYSTEM_AUTH_FAILED"],
  ["login_failed_error", "ERRORS.DETAIL.LOGIN_FAILED_ERROR"],
]);

@Injectable({
  providedIn: "root",
})
export class ErrorManagementService {
  private _latestError: Subject<IErrorEmission> = new Subject<IErrorEmission>();

  public getLatestError$() {
    return this._latestError.asObservable();
  }

  public handleException(exceptionData: IDefaultErrorResponse) {
    const translatedGeneralErrorMessage =
      CTranslatedErrorGeneralMessage.get(exceptionData.generalErrorName) ?? "ERRORS.GENERAL.DEFAULT_ERROR";
    const translatedDetailErrorMessage =
      CTranslatedErrorDetailMessage.get(exceptionData.errorCode) ?? "ERRORS.DETAIL.DEFAULT_ERROR";
    const emission: IErrorEmission = {
      generalErrorName: exceptionData.generalErrorName,
      errorCode: exceptionData.errorCode,
      generalErrorMessage: translatedGeneralErrorMessage,
      detailErrorMessage: translatedDetailErrorMessage,
      errorTracingId: exceptionData.errorTracingId ?? undefined,
    };
    this._latestError.next(emission);
  }
}
