<ng-container *ngIf="locale">
  <button
    fd-button
    glyph="translate"
    [fdMenu]="true"
    [label]="locale | uppercase"
    [ariaLabel]="locale | uppercase"
    [fdMenuTrigger]="translateMenu"
  >
  </button>
  <fd-menu #translateMenu>
    <li fd-menu-item *ngFor="let item of languageList" (onSelect)="onChangeLanguage(item)">
      <a fd-menu-interactive>
        <span fd-menu-title>{{ item | uppercase }}</span>
      </a>
    </li>
  </fd-menu>
</ng-container>
