import { Component, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { LOGIN_ROUTE } from "./constants/routes.constant";
import { NotificationManagerComponent } from "./layout/notification-manager/notification-manager.component";
import { AuthService } from "./services/auth.service";
import { ErrorManagementService } from "./services/error-management.service";
import { LanguageService } from "./services/language.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy {
  @ViewChild(NotificationManagerComponent) notificationManager!: NotificationManagerComponent;

  private ngUnsubscribe$ = new Subject<null>();

  constructor(
    languageService: LanguageService,
    private errorManagementService: ErrorManagementService,
    private authService: AuthService,
    private router: Router,
  ) {
    languageService.loadLocales();
    /**
     * Error Management Service Modal
     */
    this.errorManagementService.getLatestError$().subscribe((err) => {
      this.notificationManager.showErrorNotification(err);
    });

    this.authService.authorizationData$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((authData) => {
      if (authData.activeLogoutOperation) {
        this.router.navigateByUrl(LOGIN_ROUTE);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
  }
}
