import { Params } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { format } from "date-fns";

export function formatToSapRoleDate(date: string | Date) {
  return date ? format(typeof date == "string" ? Date.parse(date) : date, "yyyyMMdd") : "";
}

export function formatQueryParamsWithTimestamp(params: Params): Params {
  return { ...params, time: new Date().getTime() };
}

export function formatCurrentLocale(translateService: TranslateService) {
  let lang = translateService.currentLang;
  lang = lang == "en" ? "en-US" : "de-DE";
  return lang;
}

/**
 * format byte sizes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */
export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
