import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface ProcessWizardSummaryItem {
  title: string;
  label: string;
  values: string[];
  canModifyStep?: boolean;
}

export interface ProcessWizardSummaryStepConfig {
  label: string;
  values: () => string[];
  canModifyStep?: boolean;
}

@Component({
  selector: "app-process-wizard-step-summary",
  templateUrl: "./process-wizard-step-summary.component.html",
  styleUrls: ["./process-wizard-step-summary.component.scss"],
})
export class ProcessWizardStepSummaryComponent {
  @Input()
  summaries?: ProcessWizardSummaryItem[];

  @Output()
  edit = new EventEmitter<number>();

  public onEdit(index: number) {
    this.edit.emit(index + 1);
  }

  public canEditStep(item: ProcessWizardSummaryItem) {
    return item.canModifyStep !== undefined ? item.canModifyStep : true;
  }
}
