import { Component, Input } from "@angular/core";

export interface WizardActionItem {
  title: string;
  icon: string;
  description: string;
  onActionClick: () => void;
}

@Component({
  selector: "app-wizard-action-list",
  templateUrl: "./wizard-action-list.component.html",
  styleUrls: ["./wizard-action-list.component.scss"],
})
export class WizardActionListComponent {
  @Input()
  actions: WizardActionItem[] = [];

  @Input()
  title?: string;
}
