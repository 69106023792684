<fd-popover #deletePopover placement="bottom" [noArrow]="false">
  <fd-popover-control>
    <button fd-button [label]="'BUTTONS.DELETE' | translate" glyph="delete" fdType="negative"></button>
  </fd-popover-control>
  <fd-popover-body>
    <div class="popover-content">
      <p>{{ "MESSAGES.CONFIRM_DELETE" | translate }}</p>
      <p class="item">{{ item }}</p>
    </div>
    <div fd-popover-body-footer>
      <div fd-bar barDesign="footer" fdCompact>
        <div fd-bar-middle>
          <fd-button-bar
            [label]="'BUTTONS.YES' | translate"
            fdType="negative"
            (click)="onConfirmDelete()"
          ></fd-button-bar>
          <fd-button-bar
            [label]="'BUTTONS.NO' | translate"
            fdType="standard"
            (click)="deletePopover.close()"
          ></fd-button-bar>
        </div>
      </div>
    </div>
  </fd-popover-body>
</fd-popover>
