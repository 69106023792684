import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { DialogRef, MessageToastService } from "@fundamental-ngx/core";
import { DynamicFormItem, DynamicFormValue, FormGeneratorComponent } from "@fundamental-ngx/platform";
import { TranslateService } from "@ngx-translate/core";
import { lastValueFrom } from "rxjs";
import { UserStoreService } from "../../services/user-store.service";

@Component({
  selector: "app-change-password-dialog",
  templateUrl: "./change-password-dialog.component.html",
  styleUrls: ["./change-password-dialog.component.scss"],
})
export class ChangePasswordDialogComponent {
  @ViewChild(FormGeneratorComponent) formGenerator!: FormGeneratorComponent;

  public errorTrack = {
    errorOcurred: false,
    errorMessage: "",
  };

  public changePasswordFields: DynamicFormItem[] = [
    {
      type: "password",
      name: "oldPassword",
      controlType: "password",
      message: this.translateService.instant("APP.CHANGE_PASSWORD.OLD_PASSWORD"),
      placeholder: this.translateService.instant("APP.CHANGE_PASSWORD.OLD_PASSWORD"),
      validators: [Validators.required],
    },
    {
      type: "password",
      name: "newPassword",
      controlType: "password",
      message: this.translateService.instant("APP.CHANGE_PASSWORD.NEW_PASSWORD"),
      placeholder: this.translateService.instant("APP.CHANGE_PASSWORD.NEW_PASSWORD"),
      validators: [Validators.required],
      validate: (value: string) => {
        const oldPassword = (this.formGenerator.formFields[0] as any)._value;
        const passwordRepeat = (this.formGenerator.formFields[2] as any)._value;
        if (value === oldPassword) {
          return this.translateService.instant("APP.CHANGE_PASSWORD.PASSWORDS_ARE_THE_SAME_ERROR");
        }
        if (passwordRepeat) {
          if (passwordRepeat !== value) {
            return this.translateService.instant("APP.CHANGE_PASSWORD.PASSWORDS_DO_NOT_MATCH_ERROR");
          }
        }
        return null;
      },
    },
    {
      type: "password",
      name: "newPasswordRepeat",
      controlType: "password",
      message: this.translateService.instant("APP.CHANGE_PASSWORD.NEW_PASSWORD_REPEAT"),
      placeholder: this.translateService.instant("APP.CHANGE_PASSWORD.NEW_PASSWORD_REPEAT"),
      validators: [Validators.required],
      validate: (value: string) => {
        // TODO: refactor the following line
        const newPassword = (this.formGenerator.formFields[1] as any)._value;
        if (value !== newPassword) {
          return this.translateService.instant("APP.CHANGE_PASSWORD.PASSWORDS_DO_NOT_MATCH_ERROR");
        }
        return null;
      },
    },
  ];

  constructor(
    private translateService: TranslateService,
    public dialogRef: DialogRef<ChangePasswordDialogComponent>,
    private userStoreService: UserStoreService,
    private messageToastService: MessageToastService,
    private cdr: ChangeDetectorRef,
  ) {}

  public onSubmitChangePassword() {
    this.formGenerator.submit();
  }

  public async onChangePasswordFormSubmitted(formFieldValues: DynamicFormValue) {
    await lastValueFrom(
      this.userStoreService.changePassword$({
        old_password: formFieldValues["oldPassword"],
        password: formFieldValues["newPassword"],
        confirm_password: formFieldValues["newPasswordRepeat"],
      }),
    )
      .then(() => {
        const successMessage = this.translateService.instant("APP.CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS_MESSAGE");
        this.messageToastService.open(successMessage, {
          duration: 5000,
        });
        this.dialogRef.close();
      })
      .catch((errorData) => {
        this.errorTrack.errorOcurred = true;
        this.errorTrack.errorMessage = errorData.error.additionalData.non_field_errors[0];
        this.cdr.detectChanges();
        const errorMessage = this.translateService.instant("APP.CHANGE_PASSWORD.CHANGE_PASSWORD_FAIL_MESSAGE");
        this.messageToastService.open(errorMessage, {
          duration: 5000,
        });
      });
  }
}
