export const LOCAL_STORAGE_AUTH_DATA = "auth_data";
export const ENVIRONMENT_INJECT_TOKEN = "inject:environment";
export const LOCAL_STORAGE_LOCALE_DATA = "user_locale";
export const DEFAULT_LOCALE = "de";
export const EMPTY_STRING = "";
export const DEFAULT_TABLE_PAGE_SIZE = 50;

/**
 * LaunchPad Permissions
 */
export const APP_PERMISSION_FRONTEND_ADMIN = "frontend_admin";

/**
 * Interceptor constants
 */
export const AUTH_INTERCEPTOR_SKIP_INTERCEPTION = "Marbles-Skip-Auth-Interceptor";
export const ERROR_INTERCEPTOR_SKIP_INTERCEPTION = "Marbles-Skip-Interceptor";
