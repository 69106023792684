import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { ERROR_INTERCEPTOR_SKIP_INTERCEPTION } from "../constants/marbles.constants";
import { ErrorManagementService } from "../services/error-management.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorManagementService: ErrorManagementService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let intercept = true;
    // Remove header before sending
    if (req.headers.has(ERROR_INTERCEPTOR_SKIP_INTERCEPTION)) {
      intercept = false;
      req = req.clone({ headers: req.headers.delete(ERROR_INTERCEPTOR_SKIP_INTERCEPTION, "x") });
    }

    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          const errorResponse: HttpErrorResponse = err;
          if (errorResponse.error && errorResponse.status !== 401 && intercept) {
            this.errorManagementService.handleException(errorResponse.error);
          }
        }
        return throwError(() => err);
      }),
    );
  }
}
