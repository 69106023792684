<ng-container *ngIf="!(processFinished || sendingData); else wizardFinishedTpl">
  <!-- The Wizard -->
  <fd-wizard
    [appendToWizard]="false"
    class="launchpad-wizard"
    [displaySummaryStep]="true"
    contentHeight="calc(100% - 116px)"
  >
    <fd-wizard-navigation>
      <ul fd-wizard-progress-bar size="sm" role="list" aria-label="Wizard Steps">
        <li
          fd-wizard-step
          *ngFor="let step of steps; let i = index"
          [status]="step.status"
          (statusChange)="step.status = $event"
          [stepClickValidator]="stepValidator(step)"
          [label]="step.label | translate"
          [ariaLabel]="step.label | translate"
          (keydown)="handleFocus($event, i)"
          [isSummary]="step.isSummary ?? false"
        >
          <fd-wizard-step-indicator>
            <span *ngIf="!step.glyph">{{ i + 1 }}</span>
            <fd-icon
              *ngIf="step.glyph"
              class="fd-wizard__icon"
              [font]="step.glyphFont || 'SAP-icons'"
              [glyph]="step.glyph"
            ></fd-icon>
          </fd-wizard-step-indicator>
          <fd-wizard-content size="sm">
            <h3 fd-title>{{ step.label | translate }}</h3>
            <fd-message-strip
              type="information"
              [dismissible]="false"
              *ngIf="step.messageStrip"
              style="margin-bottom: 0.5rem"
            >
              {{ step.messageStrip | translate }}
            </fd-message-strip>
            <ng-container [ngTemplateOutlet]="step.template" *ngIf="step.template"></ng-container>
            <app-process-wizard-step-summary [summaries]="summaries" (edit)="goToStep($event)" *ngIf="step.isSummary" />
            <fd-wizard-next-step *ngIf="!(isSummaryStep(i) || isFinalStep(i))">
              <button
                fd-button
                fdCompact
                fdType="emphasized"
                glyph="navigation-right-arrow"
                (click)="goToStep(i + 2)"
                [disabled]="!step.enableNextStep()"
              >
                {{ "BUTTONS.NEXT_STEP" | translate }}
              </button>
            </fd-wizard-next-step>

            <fd-wizard-next-step *ngIf="isFinalStep(i) && !isSummaryStep(i)">
              <button fd-button fdType="emphasized" (click)="prepareSummary()" [disabled]="!step.enableNextStep()">{{
                "BUTTONS.REVIEW" | translate
              }}</button>
            </fd-wizard-next-step>
            <fd-wizard-next-step *ngIf="isSummaryStep(i)">
              <button fd-button fdType="emphasized" (click)="submitData()" [disabled]="!step.enableNextStep()">{{
                submitLabel | translate
              }}</button>
            </fd-wizard-next-step>
          </fd-wizard-content>
        </li>
      </ul>
    </fd-wizard-navigation>
  </fd-wizard>
</ng-container>
<ng-template #wizardFinishedTpl>
  <section class="launchpad-wizard-complete">
    <h3 fd-title *ngIf="busyTitle">{{ busyTitle }}</h3>
    <div fd-busy-indicator-extended>
      <fd-busy-indicator
        [loading]="sendingData"
        [label]="'MESSAGES.PLEASE_WAIT' | translate"
        [title]="'MESSAGES.PLEASE_WAIT' | translate"
      >
        <fd-layout-grid *ngIf="processFinished">
          <div fdLayoutGridRow>
            <div fdLayoutGridCol colGrow>
              <ng-container *ngTemplateOutlet="wizardFinished"></ng-container>
            </div>
            <div [fdLayoutGridCol]="4" *ngIf="actions.length">
              <app-wizard-action-list
                [actions]="actions"
                title="{{ 'APP.RUN_PROCESSES.WIZARD_ACTIONS_TITLE' | translate }}"
              />
            </div>
          </div>
        </fd-layout-grid>
      </fd-busy-indicator>
    </div>
  </section>
</ng-template>
