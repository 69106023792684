import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes, TitleStrategy } from "@angular/router";
import { canActivateLaunchpad } from "./guards/launchpad.guard";
import { PageTitleStrategyService } from "./services/page-title-strategy.service";

const routes: Routes = [
  {
    path: "apps",
    loadChildren: () => import("./launchpad/launchpad.module").then((m) => m.LaunchpadModule),
    canActivate: [canActivateLaunchpad],
  },
  {
    path: "",
    loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: false })],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: PageTitleStrategyService }],
})
export class AppRoutingModule {}
