import { Injectable } from "@angular/core";
import { LOCAL_STORAGE_AUTH_DATA } from "../constants/marbles.constants";
import { IAuthToken } from "../interfaces/auth.model";

@Injectable({
  providedIn: "root",
})
export class AuthTokenService {
  public saveAuthData(authData: IAuthToken) {
    const data = JSON.stringify(authData);
    localStorage.setItem(LOCAL_STORAGE_AUTH_DATA, data);
  }

  public loadAuthData() {
    const dataSerialized = localStorage.getItem(LOCAL_STORAGE_AUTH_DATA);
    try {
      if (!dataSerialized) {
        return;
      }
      const data: IAuthToken = JSON.parse(dataSerialized);
      return data;
    } catch {
      return;
    }
  }

  public deleteAuthData() {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_DATA);
  }
}
