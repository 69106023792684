import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { RouteGuard } from "types";
import { LOGIN_ROUTE } from "../constants/routes.constant";
import { AuthService } from "../services/auth.service";

export const canActivateLaunchpad = (): RouteGuard => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.isAuthenticated) {
    return true;
  }
  return router.parseUrl(LOGIN_ROUTE);
};
