import { SortDirection, TableDataProvider, TableState } from "@fundamental-ngx/platform";
import { BehaviorSubject, Observable, of, tap } from "rxjs";

export class MarblesTableDataProvider<T extends Record<string, any>> extends TableDataProvider<T> {
  public loading = new BehaviorSubject(true);

  /**
   * holds the original items that were addede
   */
  public originalItems: T[] = [];

  public override items: T[] = [];

  public isNew = true;

  /**
   * Update the cached original Items
   * @param {any} data:T[]
   * @returns {any}
   */
  public update(data: T[]) {
    this.originalItems = data;
    this.isNew = false;
  }

  /**
   * Get the last loaded items
   * @returns Observable of data array
   */
  public previousData$() {
    return of(this.originalItems);
  }

  /**
   * Method should be implement with service logic to fetch data from backend
   * @returns {Observable<T[]>}
   */
  protected doFetch(): Observable<T[]> {
    return new Observable();
  }

  /**
   * The fecth method which is called in table
   * @param {any} tableState?:TableState
   * @returns {any}
   */
  override fetch(tableState?: TableState): Observable<T[]> {
    this.loading.next(true);

    if (this.originalItems.length === 0)
      return this.doFetch().pipe(
        tap((data) => {
          this.loading.next(false);
          this.update(data);
        }),
      );
    let items = this.originalItems;

    if (this.isNew && tableState) {
      tableState.page.currentPage = 1;
      this.isNew = false;
    }

    // apply searching
    if (tableState?.searchInput) {
      items = this.search(items, tableState);
    }

    // apply sorting
    if (tableState?.sortBy) {
      items = this.sort(items, tableState);
    }

    this.totalItems = items.length;

    // Apply paging
    if (tableState?.page?.currentPage && tableState?.page?.pageSize) {
      const startIndex = (tableState.page.currentPage - 1) * tableState.page.pageSize;
      items = items.slice(startIndex, startIndex + tableState.page.pageSize);
    }

    this.items = items;

    return of(items);
  }

  /**
   * The sort to data based on table state
   * @param {any} tableState?:TableState
   * @returns {any}
   */
  public sort(items: T[], { sortBy }: TableState): T[] {
    sortBy = sortBy.filter(({ field }) => !!field);

    if (sortBy.length === 0) {
      return items;
    }

    return items.sort(
      (a, b) =>
        sortBy
          .map(({ field, direction }) => {
            const ascModifier = direction === SortDirection.ASC ? 1 : -1;
            return this.applySort(a, b, field as string) * ascModifier;
          })
          .find((result, index, list) => result !== 0 || index === list.length - 1) || 0,
    );
  }

  applySort(a: T, b: T, key?: string): number {
    if (key) {
      a = this.getNestedValue(key, a);
      b = this.getNestedValue(key, b);
    }
    return a > b ? 1 : a === b ? 0 : -1;
  }

  getNestedValue<T extends Record<string, any>>(key: string, object: T): any {
    return key.split(".").reduce((a, b) => (a ? a[b] : null), object);
  }
}
