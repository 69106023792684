<div fd-bar barDesign="header" class="title-bar">
  <div fd-bar-left>
    <fd-button-bar
      aria-label="back"
      fd-button
      fdType="transparent"
      [glyph]="navigationArrow$ | async"
      (click)="onNavBack()"
    ></fd-button-bar>
    <fd-bar-element>
      <h3 fd-title>{{ title }}</h3>
    </fd-bar-element>
  </div>

  <div fd-bar-right>
    <fd-bar-element>
      <div class="title-bar-button-group">
        <ng-content></ng-content>
      </div>
    </fd-bar-element>
  </div>
</div>
