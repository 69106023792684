import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { first, lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PageTitleStrategyService extends TitleStrategy {
  private appTitle = null;
  constructor(private translateService: TranslateService, private readonly title: Title) {
    super();
    this.translateService
      .get("APP_TITLE")
      .pipe(first())
      .subscribe((translation) => {
        this.appTitle = translation;
      });
  }

  override async updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title) {
      const translation = await lastValueFrom(this.translateService.get(title));
      this.title.setTitle(`${translation} - ${this.appTitle}`);
    }
  }
}
