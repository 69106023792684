<h4 *ngIf="title" fd-title><fd-icon glyph="begin"></fd-icon> {{ title }}</h4>
<ul fd-list [navigationIndicator]="true" [byline]="true" fdCompact>
  <li fd-list-item *ngFor="let item of actions">
    <a fd-list-link [navigationIndicator]="true" (click)="item.onActionClick()" [title]="item.title | translate">
      <span fd-list-thumbnail><fd-icon [glyph]="item.icon"></fd-icon></span>
      <div fd-list-content>
        <div fd-list-title [wrap]="true">{{ item.title | translate }}</div>
        <div fd-list-byline [wrap]="true">{{ item.description | translate }}</div>
      </div>
    </a>
  </li>
</ul>
