import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export const API_BASE_URL = environment.apiGateway

@Injectable({
  providedIn: 'root'
})
export class ApiClientService extends HttpClient {
}
