import { Component, Input } from "@angular/core";
import { MessageToastService } from "@fundamental-ngx/core";
import { TranslateService } from "@ngx-translate/core";
import { NGXLogger } from "ngx-logger";

export enum EClipboardDataType {
  LIST_DATA,
  TABLE_DATA,
  STRING_DATA,
}

export interface IClipboardTableData {
  headers: string[];
  rows: string[][];
}

export function isFirefoxBrowser() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf("firefox") > -1;
}

@Component({
  selector: "marbles-copy-clipboard-button",
  templateUrl: "./copy-clipboard-button.component.html",
  styleUrls: ["./copy-clipboard-button.component.scss"],
})
export class CopyClipboardButtonComponent {
  @Input() iconName = "copy";
  @Input() dataType: EClipboardDataType = EClipboardDataType.STRING_DATA;
  @Input() stringData?: string;
  @Input() listData?: string[];
  @Input() tableData?: IClipboardTableData;
  @Input() data?: () => string | string[] | IClipboardTableData;
  @Input() htmlTable = false;
  @Input() htmlList = false;
  @Input() alternativeText?: string;
  @Input() disableButton = false;

  constructor(
    private messageToastService: MessageToastService,
    private translateService: TranslateService,
    private logger: NGXLogger,
  ) {}

  private generateTableClipboard() {
    if (!this.tableData) {
      return "";
    }
    let strRepresentation = "<BODY><TABLE BORDER><thead><tr>";
    for (const header of this.tableData.headers) {
      strRepresentation += `<td>${header}</td>`;
    }
    strRepresentation += "</tr></thead><tbody>";
    for (const row of this.tableData.rows) {
      strRepresentation += "<tr>";
      for (const column of row) {
        strRepresentation += `<td>${column}</td>`;
      }
      strRepresentation += "</tr>";
    }
    strRepresentation += "</tbody></TABLE></BODY>";
    return strRepresentation;
  }

  private generateListClipboard() {
    if (!this.listData) {
      return "";
    }

    let strRepresentation = "<BODY><ol>";
    for (const item of this.listData) {
      strRepresentation += `<li>${item}</li>`;
    }
    strRepresentation += "</ol></BODY>";
    return strRepresentation;
  }

  private generateHTMLBlob(htmlString: string) {
    const blob = new Blob([htmlString], { type: "text/html" });
    const cpData = [new ClipboardItem({ ["text/html"]: blob })];
    return cpData;
  }

  private prepareData() {
    const data = this.data?.();

    if (this.htmlTable) {
      this.dataType = EClipboardDataType.TABLE_DATA;
      if (data) this.tableData = data as IClipboardTableData;
    } else if (this.htmlList) {
      this.dataType = EClipboardDataType.LIST_DATA;
      if (data) this.listData = data as string[];
    } else if (data) {
      if (typeof data !== "string") {
        this.stringData = JSON.stringify(data);
      }
      this.stringData = data as string;
    }
  }

  public onCopyClipboard() {
    this.prepareData();
    let copied = true;
    switch (this.dataType) {
      case EClipboardDataType.STRING_DATA:
        navigator.clipboard.writeText(this.stringData ?? "");
        break;
      case EClipboardDataType.TABLE_DATA: {
        const tableData = this.generateTableClipboard();
        isFirefoxBrowser()
          ? navigator.clipboard.writeText(tableData)
          : navigator.clipboard.write(this.generateHTMLBlob(tableData));
        break;
      }
      case EClipboardDataType.LIST_DATA: {
        const listData = this.generateListClipboard();
        isFirefoxBrowser()
          ? navigator.clipboard.writeText(listData)
          : navigator.clipboard.write(this.generateHTMLBlob(listData));
        break;
      }
      default:
        copied = false;
        this.logger.warn(`Unknown data type to copy!`);
        break;
    }

    if (copied) {
      this.messageToastService.open(this.translateService.instant("MESSAGES.TOAST.CONTENT_COPIED"), {
        duration: 2500,
        animated: true,
      });
    } else {
      this.messageToastService.open(this.translateService.instant("MESSAGES.TOAST.CONTENT_NOT_COPIED"), {
        duration: 2500,
        animated: true,
      });
    }
  }
}
