import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import { Injectable, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { DEFAULT_LOCALE, LOCAL_STORAGE_LOCALE_DATA } from "../constants/marbles.constants";
import { formatCurrentLocale } from "../launchpad/apps/utils/formatter";
import { UserStoreService } from "./user-store.service";

export const AVAILABLE_LOCALES = ["en", "de"];

@Injectable({
  providedIn: "root",
})
export class LanguageService implements OnDestroy {
  /**
   * if ngUnsubscribe$ emits a value (null) --> terminate all running subscriptions inside the service
   */
  private ngUnsubscribe$: Subject<null> = new Subject();

  private locale = new BehaviorSubject<string>(DEFAULT_LOCALE);

  constructor(private userStore: UserStoreService, private translateService: TranslateService) {
    this.loadAngularLocales();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
  }

  public getUserLocale$() {
    return this.locale.asObservable();
  }

  private _updateAppLocale(locale: string) {
    localStorage.setItem(LOCAL_STORAGE_LOCALE_DATA, locale);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // const browserLang = translateService.getBrowserLang();
    // translateService.use(browserLang?.match(/en|de/) ? browserLang : "en");

    this.translateService.use(locale);
  }

  public setUserLocale(locale: string) {
    if (AVAILABLE_LOCALES.includes(locale)) {
      this.locale.next(locale);
    } else {
      const splitLocale = locale.toLocaleLowerCase().split("-");
      if (splitLocale.length > 1 && AVAILABLE_LOCALES.includes(splitLocale[0])) {
        this.locale.next(splitLocale[0]);
      }
    }
  }

  public loadAngularLocales() {
    registerLocaleData(localeDe, "de-DE", localeDeExtra);
  }

  get currentLangForDate() {
    return formatCurrentLocale(this.translateService);
  }

  public loadLocales() {
    this.translateService.addLangs(AVAILABLE_LOCALES);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang(DEFAULT_LOCALE);

    const locale = localStorage.getItem(LOCAL_STORAGE_LOCALE_DATA);
    if (locale) {
      this.setUserLocale(locale);
    }
    this.getUserLocale$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((locale) => this._updateAppLocale(locale));
  }
}
