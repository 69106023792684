import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FundamentalNgxCdkModule } from "@fundamental-ngx/cdk";
import { FundamentalNgxCoreModule } from "@fundamental-ngx/core";
import { FundamentalNgxPlatformModule } from "@fundamental-ngx/platform";
import { TranslateModule } from "@ngx-translate/core";
import { DataSizePipe } from "../pipes/data-size.pipe";
import { ChangePasswordDialogComponent } from "./change-password-dialog/change-password-dialog.component";
import { ConfirmDeleteDialogComponent } from "./confirm-delete-dialog/confirm-delete-dialog.component";
import { CopyClipboardButtonComponent } from "./copy-clipboard-button/copy-clipboard-button.component";
import { HeaderComponent } from "./header/header.component";
import { LanguageSwitcherComponent } from "./language-switcher/language-switcher.component";
import { ProcessWizardStepSummaryComponent } from "./process-wizard-step-summary/process-wizard-step-summary.component";
import { ProcessWizardComponent } from "./process-wizard/process-wizard.component";
import { SapUserDetailsDialogComponent } from "./sap-user-details-dialog/sap-user-details-dialog.component";
import { StatusIconComponent } from "./status-icon/status-icon.component";
import { TitlebarComponent } from "./titlebar/titlebar.component";
import { WizardActionListComponent } from "./wizard-action-list/wizard-action-list.component";

@NgModule({
  declarations: [
    HeaderComponent,
    TitlebarComponent,
    ConfirmDeleteDialogComponent,
    LanguageSwitcherComponent,
    WizardActionListComponent,
    CopyClipboardButtonComponent,
    ProcessWizardComponent,
    ProcessWizardStepSummaryComponent,
    SapUserDetailsDialogComponent,
    ChangePasswordDialogComponent,
    StatusIconComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FundamentalNgxCdkModule,
    FundamentalNgxCoreModule,
    FundamentalNgxPlatformModule,
    DataSizePipe,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    TitlebarComponent,
    ConfirmDeleteDialogComponent,
    FundamentalNgxCdkModule,
    FundamentalNgxCoreModule,
    FundamentalNgxPlatformModule,
    TranslateModule,
    WizardActionListComponent,
    CopyClipboardButtonComponent,
    ProcessWizardComponent,
    ProcessWizardStepSummaryComponent,
    SapUserDetailsDialogComponent,
    StatusIconComponent,
  ],
})
export class LayoutModule {}
