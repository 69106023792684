import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild } from "@angular/core";
import { DialogRef } from "@fundamental-ngx/core";
import { TableComponent } from "@fundamental-ngx/platform";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { IGetSapUserDetailsData, ISapRole } from "src/app/interfaces/sap-user-details";
import { MarblesTableDataSource } from "src/app/shared/marble-table-data-source";
import { MarblesTableDataProvider } from "src/app/shared/marbles-table-data-provider";

class RolesTableDataProvider extends MarblesTableDataProvider<ISapRole> {}

@Component({
  selector: "app-sap-user-details-dialog",
  templateUrl: "./sap-user-details-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./sap-user-details-dialog.component.scss"],
})
export class SapUserDetailsDialogComponent implements OnDestroy {
  public data: IGetSapUserDetailsData | null = null;
  public searchInput$ = new BehaviorSubject<string>("");
  public searchText = "";
  public rolesRows: ISapRole[] = [];
  public displayedRoleRows: ISapRole[] = [];
  public rolesColumns: string[] = [];
  public autoExpand = false;
  private ngUnsubscribe$ = new Subject<null>();

  @ViewChild(TableComponent) table!: TableComponent;
  public availableSapRolesDataSource = new MarblesTableDataSource<ISapRole>(new RolesTableDataProvider());

  constructor(public dialogRef: DialogRef<IGetSapUserDetailsData>, private changeDetectorRef: ChangeDetectorRef) {
    this.searchInput$.asObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(this.searchData.bind(this));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
  }

  onSearch() {
    this.dialogRef.loading(true);
    this.searchInput$.next(this.searchText);
  }

  deepFilter(data: object, searchText: string) {
    const newData = {};
    const addToObject = <T>(data: T, key: string, value: any) => (data[key as keyof T] = value);

    for (const [key, value] of Object.entries(data)) {
      if (value && String(value).toLocaleLowerCase().includes(searchText)) {
        addToObject<typeof newData>(newData, key, value);
      }
    }
    return newData;
  }

  private _filterData(data: IGetSapUserDetailsData | null, search: string) {
    if (search) {
      const newData = {} as IGetSapUserDetailsData;
      const searchText = search.toLocaleLowerCase();
      const addToObject = (key: string, value: any) => (newData[key as keyof IGetSapUserDetailsData] = value);
      if (data) {
        for (const [key, value] of Object.entries(data)) {
          if (["username", "usernameAlias"].includes(key)) {
            addToObject(key, value);
            continue;
          }

          if (key == "roles") {
            const newRoles = [];
            for (let index = 0; index < value.length; index++) {
              const element: ISapRole = value[index];
              if (Object.keys(this.deepFilter(element, searchText)).length) {
                newRoles.push(element);
              }
            }
            addToObject(key, newRoles);
          }
          const filtered = this.deepFilter(value, searchText);
          if (Object.keys(filtered).length > 0) {
            addToObject(key, filtered);
          }
        }
      }
      this.autoExpand = true;
      return newData;
    } else {
      this.autoExpand = false;
      return data;
    }
  }

  private searchData(search: string) {
    this.data = this._filterData(this.dialogRef.data, search);
    const roles = this.data?.roles;
    if (roles) {
      this.rolesRows = roles;
      if (roles.length) {
        this.rolesColumns = Object.keys(roles[0]);
      }
    } else {
      this.rolesRows = [];
    }
    this.availableSapRolesDataSource.updateTableData(this.table, this.rolesRows);
    this.dialogRef.loading(false);
  }

  returnZero() {
    return 0;
  }
}
