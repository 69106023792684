<div class="app-header">
  <header class="page-header">
    <div class="container">
      <fd-shellbar>
        <fd-shellbar-logo>
          <a href="#" class="fd-shellbar__logo" aria-label="Marbles" (click)="onRouteToHome()">
            <img src="../../../assets/images/logo-black.svg" class="marbles-logo" alt="" />
          </a>
        </fd-shellbar-logo>
        <button
          fd-shellbar-side-nav
          (fdkClicked)="launchpadService.toggleVerticalNavigationCollapse()"
          fd-button
          fdType="transparent"
          glyph="menu2"
          style="width: 2.75rem"
          *ngIf="authenticated"
        ></button>
        <fd-shellbar-actions [user]="user" [userMenu]="userMenu" [closePopoverOnSelect]="true" *ngIf="authenticated">
          <app-language-switcher />
        </fd-shellbar-actions>
        <fd-shellbar-actions [closePopoverOnSelect]="true" *ngIf="!authenticated">
          <app-language-switcher />
          <button
            fd-button
            glyph="employee"
            [ariaLabel]="'HEADER.BUTTON.LOGIN' | translate"
            [label]="'HEADER.BUTTON.LOGIN' | translate"
            (click)="onShowLoginDialog()"
            style="margin-left: 0.5rem"
          ></button>
        </fd-shellbar-actions>
      </fd-shellbar>
    </div>
  </header>
</div>
