import { Component, Input } from "@angular/core";
import { ColorAccent } from "@fundamental-ngx/core";

export interface StatusIconItem {
  colorAccent: ColorAccent;
  glyph: string;
}
export type StatusIconType = "success" | "error" | "pause" | "missing" | "upload";
@Component({
  selector: "app-status-icon",
  templateUrl: "./status-icon.component.html",
  styleUrls: ["./status-icon.component.scss"],
})
export class StatusIconComponent {
  private readonly statuses: { [key: string]: StatusIconItem } = {
    success: {
      colorAccent: 8,
      glyph: "status-positive",
    },
    error: {
      colorAccent: 3,
      glyph: "status-negative",
    },
    pause: {
      colorAccent: 10,
      glyph: "pause",
    },
    missing: {
      colorAccent: 10,
      glyph: "question-mark",
    },
    upload: {
      colorAccent: 10,
      glyph: "upload-to-cloud",
    },
  };

  public iconStatus: StatusIconItem = this.statuses["error"];
  public iconStatusClass = "";

  @Input() title = "";
  @Input() set status(status: StatusIconType) {
    this.iconStatus = this.statuses[status];
    this.iconStatusClass = `status-${status}`;
  }
}
