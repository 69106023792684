import { AuthService } from "../auth.service";

export abstract class BaseStoreService {
  constructor(private authService: AuthService) {
    this.authService.authorizationData$.subscribe((authData) => {
      if (!authData.loggedIn && authData.activeLogoutOperation) {
        this.cleanup();
      }
    });
  }

  protected abstract cleanup(): void;
}
