<fd-notification-body (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <fd-notification-content>
    <fd-notification-header>
      <div fd-notification-indicator type="error"></div>
      <h2 fd-notification-title [unread]="true">{{ data.generalErrorMessage! | translate }}</h2>
    </fd-notification-header>

    <p fd-notification-paragraph>
      {{ data.detailErrorMessage! | translate }}
    </p>
  </fd-notification-content>
  <fd-notification-actions>
    <button
      fd-button
      fdCompact
      fdType="transparent"
      glyph="paper-plane"
      [label]="'BUTTONS.REPORT' | translate"
      [title]="'BUTTONS.REPORT' | translate"
      (click)="reportError(data.errorTracingId)"
      *ngIf="data.errorTracingId"
    ></button>
    <button
      fd-button
      fdCompact
      fdType="transparent"
      glyph="decline"
      [title]="'BUTTONS.CLOSE' | translate"
      ariaLabel="Close"
      (click)="notificationRef.close('Close Button Click')"
    ></button>
  </fd-notification-actions>
</fd-notification-body>
