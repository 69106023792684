import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { PopoverComponent } from "@fundamental-ngx/core";

@Component({
  selector: "app-confirm-delete-dialog",
  templateUrl: "./confirm-delete-dialog.component.html",
  styleUrls: ["./confirm-delete-dialog.component.scss"],
})
export class ConfirmDeleteDialogComponent {
  @Input() item?: string;
  @Output() onConfirm = new EventEmitter();

  @ViewChild("deletePopover") dialog!: PopoverComponent;

  public onConfirmDelete() {
    this.onConfirm.emit();
    this.dialog.close?.();
  }
}
