import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IEnvironment } from "../../../environments/environment.model";

export abstract class BaseProviderService {
  constructor(protected env: IEnvironment, protected http: HttpClient) {}

  protected get<TResponseBody>(endpoint: string, params?: HttpParams, headers?: HttpHeaders) {
    return this.http.get<TResponseBody>(this.getFullURL(endpoint), {
      params,
      headers,
    });
  }

  protected post<TResponseBody>(endpoint: string, body: Record<string, any>, additionalHeaders?: HttpHeaders) {
    return this.http.post<TResponseBody>(this.getFullURL(endpoint), body, { headers: additionalHeaders });
  }

  protected patch<TResponseBody>(endpoint: string, body: any) {
    return this.http.patch<TResponseBody>(this.getFullURL(endpoint), body);
  }

  protected delete<TResponseBody>(endpoint: string, body?: any) {
    return this.http.delete<TResponseBody>(this.getFullURL(endpoint), {
      body,
    });
  }

  protected getFullURL(endpoint: string) {
    return `${this.env.apiGateway}/${endpoint}`;
  }

  protected getBlob(endpoint: string, params?: HttpParams, headers?: HttpHeaders) {
    return this.http.get(this.getFullURL(endpoint), {
      params,
      headers,
      responseType: "blob",
    });
  }
}
