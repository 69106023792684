import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, lastValueFrom, map, takeUntil } from "rxjs";
import {
  IProfileInformation,
  IUserChangePasswordPayload,
  IUserCreateModel,
  IUserDeleteModel,
  IUserPatchModel,
} from "../models/user-information.model";
import { AuthService } from "./auth.service";
import { BaseStoreService } from "./shared/base-store.service";
import { UserProviderService } from "./user-provider.service";

@Injectable({
  providedIn: "root",
})
export class UserStoreService extends BaseStoreService {
  private _ngUnsubscribe;
  private profile = new BehaviorSubject<IProfileInformation | null>(null);

  constructor(authService: AuthService, private userProviderService: UserProviderService) {
    super(authService);
    this._ngUnsubscribe = new Subject<null>();
  }

  public getUserProfileInformation$() {
    return this.userProviderService.getUserProfileInformation().pipe(
      map((data) => {
        return data.data;
      }),
    );
  }

  public getUserProfile$() {
    return this.profile.asObservable().pipe(takeUntil(this._ngUnsubscribe));
  }

  public getUsersByCompany$(companyId: string) {
    return this.userProviderService.getUsersByCompany(companyId);
  }

  public createUser$(data: IUserCreateModel) {
    return this.userProviderService.createUser(data);
  }

  public updateUser$(userId: string, data: IUserPatchModel) {
    return this.userProviderService.updateUser$(userId, data);
  }

  public deleteUser$(data: IUserDeleteModel) {
    return this.userProviderService.deleteUser$(data);
  }

  public setUserProfile() {
    lastValueFrom(this.getUserProfileInformation$()).then((profile) => {
      return this.profile.next(profile);
    });
  }

  public changePassword$(data: IUserChangePasswordPayload) {
    return this.userProviderService.changePassword$(data);
  }

  protected override cleanup(): void {
    this._ngUnsubscribe.next(null);
  }
}
