<fd-dialog>
  <fd-dialog-header>
    <h1 id="fd-dialog-header-1" fd-title>{{ "APP.CHANGE_PASSWORD.HEADER" | translate }}</h1>
  </fd-dialog-header>

  <fd-dialog-body>
    <fdp-form-generator
      (formSubmitted)="onChangePasswordFormSubmitted($event)"
      [formItems]="changePasswordFields"
    ></fdp-form-generator>
    <fd-message-strip *ngIf="errorTrack.errorOcurred" style="margin: 16px" type="error" [dismissible]="false">{{
      errorTrack.errorMessage
    }}</fd-message-strip>
  </fd-dialog-body>

  <fd-dialog-footer>
    <fd-button-bar
      fdType="emphasized"
      [label]="'BUTTONS.CHANGE_PASSWORD' | translate"
      ariaLabel="Interested Emphasized"
      (click)="onSubmitChangePassword()"
    ></fd-button-bar>
    <fd-button-bar
      [label]="'BUTTONS.CANCEL' | translate"
      (click)="dialogRef.dismiss()"
      ariaLabel="Cancel"
    ></fd-button-bar>
  </fd-dialog-footer>
</fd-dialog>
