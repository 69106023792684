import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { DialogService, ShellbarUser, ShellbarUserMenu } from "@fundamental-ngx/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject, combineLatest, takeUntil } from "rxjs";
import { HOME_ROUTE, LAUNCHPAD_ROUTE, LOGIN_ROUTE, PROFILE_ROUTE } from "src/app/constants/routes.constant";
import { IProfileInformation } from "src/app/models/user-information.model";
import { AuthService } from "src/app/services/auth.service";
import { LaunchpadService } from "src/app/services/launchpad.service";
import { UserStoreService } from "src/app/services/user-store.service";
import { ChangePasswordDialogComponent } from "../change-password-dialog/change-password-dialog.component";

interface IShellbarAction {
  glyph: string;
  callback: () => void;
  label: string;
  notificationCount: number;
  notificationLabel: string;
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnDestroy {
  private ngUnsubscribe$ = new Subject<null>();
  public user: ShellbarUser = {};
  public userMenu: ShellbarUserMenu[] = [];
  public actions: IShellbarAction[] = [];
  public callbacks = {
    logout: () => this.onLogout(),
    changePassword: () => this.onShowChangePasswordDialog(),
  };

  constructor(
    public authService: AuthService,
    private userStore: UserStoreService,
    public router: Router,
    public translateService: TranslateService,
    private dialogService: DialogService,
    public launchpadService: LaunchpadService,
  ) {
    combineLatest([this.userStore.getUserProfile$(), this.authService.authorizationData$])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([userProfile, authData]) => {
        if (!userProfile && authData.loggedIn) {
          this.userStore.setUserProfile();
        }
        if (userProfile) {
          this.setUserMenu(userProfile);
        }
        if (authData.loggedIn === false) {
          this.resetUserMenu();
        }
      });
  }

  private setUserMenu(userProfile: IProfileInformation) {
    this.user = {
      fullName: `${userProfile.first_name} ${userProfile.last_name}`,
      colorAccent: 6,
    };
    this.translateService
      .stream(["HEADER.PROFILE_MENU.CHANGE_PASSWORD", "HEADER.PROFILE_MENU.LOGOUT"])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((t) => {
        this.userMenu = [
          {
            text: t["HEADER.PROFILE_MENU.CHANGE_PASSWORD"],
            callback: this.callbacks.changePassword,
          },
          {
            text: t["HEADER.PROFILE_MENU.LOGOUT"],
            callback: this.callbacks.logout,
          },
        ];
      });
  }

  private resetUserMenu() {
    this.user = {};
    this.userMenu = [];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
  }

  onRouteToApps() {
    this.router.navigateByUrl(LAUNCHPAD_ROUTE);
  }

  onRouteToHome() {
    this.router.navigateByUrl(HOME_ROUTE);
  }

  onRouteToProfile() {
    this.router.navigateByUrl(PROFILE_ROUTE);
  }

  public get authenticated() {
    return this.authService.isAuthenticated;
  }

  public onShowLoginDialog() {
    this.router.navigateByUrl(LOGIN_ROUTE);
  }

  public onLogout() {
    this.authService.logout();
  }

  public async onShowChangePasswordDialog() {
    const dialogRef = this.dialogService.open(ChangePasswordDialogComponent, {
      maxWidth: "30rem",
      ariaLabelledBy: "fd-dialog-header-1",
      ariaDescribedBy: "fd-dialog-body-1",
      responsivePadding: true,
      focusTrapped: true,
    });
  }
}
