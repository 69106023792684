import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IEnvironment } from "../../environments/environment.model";
import { ENVIRONMENT_INJECT_TOKEN, ERROR_INTERCEPTOR_SKIP_INTERCEPTION } from "../constants/marbles.constants";
import { IApiResponse } from "../models/api-response.model";
import {
  IProfileInformation,
  IUserChangePasswordPayload,
  IUserCreateModel,
  IUserDeleteModel,
  IUserPatchModel,
} from "../models/user-information.model";
import { BaseProviderService } from "./shared/base-provider.service";

@Injectable({
  providedIn: "root",
})
export class UserProviderService extends BaseProviderService {
  private basePath = "auth";
  private usersPath = "auth/users";
  private profilePath = "auth/profile";

  constructor(
    @Inject(ENVIRONMENT_INJECT_TOKEN) protected override env: IEnvironment,
    protected override http: HttpClient,
  ) {
    super(env, http);
  }

  public getUserProfileInformation(): Observable<IApiResponse<IProfileInformation>> {
    return this.get(`${this.basePath}/profile/`);
  }

  public getUsersByCompany(companyId: string): Observable<IApiResponse<IProfileInformation[]>> {
    // Add safe, URL encoded  parameter
    const options = new HttpParams().set("companyId", companyId);
    return this.get(`${this.usersPath}/`, options);
  }

  /**
   * Create a User
   * @param user object with all changed key/value combinations
   * @returns
   */
  public createUser(user: IUserCreateModel): Observable<IApiResponse<IProfileInformation>> {
    return this.post(`${this.usersPath}/`, user);
  }

  /**
   * Patch a User
   * @param data object with all changed key/value combinations
   * @returns
   */
  public updateUser$(userId: string, user: IUserPatchModel) {
    return this.patch(`${this.usersPath}/${userId}`, user);
  }

  /**
   * Delete a User
   * @param data object with all changed key/value combinations
   * @returns
   */
  public deleteUser$(data: IUserDeleteModel) {
    return this.delete(`${this.usersPath}/`, data);
  }

  public changePassword$(data: IUserChangePasswordPayload): Observable<IApiResponse<null>> {
    const headers = new HttpHeaders({
      [ERROR_INTERCEPTOR_SKIP_INTERCEPTION]: "x",
    });
    return this.post(`${this.profilePath}/change-password`, data, headers);
  }
}
