export enum WizardStep {
  COMPLETED = "completed",
  UPCOMING = "upcoming",
  CURRENT = "current",
}
export abstract class MarbleWizard {
  abstract steps: any[];

  public goToStep(step: number): void {
    const _step = step - 1;
    for (let i = 0; i < this.steps.length; i++) {
      if (i < _step) {
        this.steps[i].status = WizardStep.COMPLETED;
      } else if (i > _step) {
        this.steps[i].status = WizardStep.UPCOMING;
      } else if (i === _step) {
        this.steps[i].status = WizardStep.CURRENT;
      }
    }
  }
}
