import { Pipe, PipeTransform } from "@angular/core";
import { formatBytes } from "../launchpad/apps/utils/formatter";

@Pipe({
  name: "dataSize",
  standalone: true,
})
export class DataSizePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return formatBytes(value as number);
  }
}
