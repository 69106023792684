import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RtlService } from "@fundamental-ngx/core";
import { Observable, map, of } from "rxjs";

@Component({
  selector: "app-titlebar",
  templateUrl: "./titlebar.component.html",
  styleUrls: ["./titlebar.component.scss"],
})
export class TitlebarComponent implements OnInit {
  @Input() title = "";
  navigationArrow$: Observable<string> = of("navigation-left-arrow");

  constructor(private router: Router, private activeRoute: ActivatedRoute, private _rtlService: RtlService) {}
  ngOnInit(): void {
    this.navigationArrow$ = this._rtlService.rtl.pipe(
      map((isRtl) => (isRtl ? "navigation-right-arrow" : "navigation-left-arrow")),
    );
  }
  onNavBack() {
    this.router.navigate([".."], { relativeTo: this.activeRoute });
  }
}
