import { Component } from "@angular/core";
import { NotificationRef, NotificationService } from "@fundamental-ngx/core";
import { IErrorEmission } from "src/app/services/error-management.service";
import { ErrorNotificationComponent } from "../error-notification/error-notification.component";

@Component({
  selector: "app-notification-manager",
  standalone: true,
  template: "",
  imports: [ErrorNotificationComponent],
})
export class NotificationManagerComponent {
  private notificationReferences: NotificationRef[] = [];

  constructor(private notificationService: NotificationService) {}

  showErrorNotification(error: IErrorEmission) {
    const newId = `error-notice-${this.notificationReferences.length + 1}`;
    const notificationRef = this.notificationService.open(ErrorNotificationComponent, {
      id: newId,
      data: error,
      width: "30rem",
    });

    this.notificationReferences.push(notificationRef);
  }
}
