import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { ActionSheetModule, ButtonModule, NotificationModule, NotificationRef } from "@fundamental-ngx/core";
import { TranslateModule } from "@ngx-translate/core";
import { IErrorEmission } from "src/app/services/error-management.service";

const DEFAULT_NOTIFICATION_TIMEOUT = 10000;
const ERROR_REPORTING_MAIL = "alexander.baesler@marbles.run";
const ERROR_REPORTING_CC = "peter.voss@dej-technology.de";
@Component({
  selector: "app-error-notificaton",
  standalone: true,
  templateUrl: "./error-notification.component.html",
  styleUrls: ["./error-notification.component.scss"],
  imports: [CommonModule, NotificationModule, ActionSheetModule, TranslateModule, ButtonModule],
})
export class ErrorNotificationComponent {
  timeoutId?: ReturnType<typeof setTimeout>;
  data!: IErrorEmission;

  constructor(public notificationRef: NotificationRef) {
    this.data = notificationRef.data;
    this.scheduleDismiss(notificationRef);
  }

  scheduleDismiss(notificationRef: NotificationRef, timeout?: number) {
    this.timeoutId = setTimeout(
      () => this.notificationRef?.dismiss("dismissed"),
      timeout ?? DEFAULT_NOTIFICATION_TIMEOUT,
    );
  }

  onMouseEnter() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }

  onMouseLeave() {
    if (this.notificationRef) this.scheduleDismiss(this.notificationRef);
  }

  reportError(id: string) {
    location.href = `mailto:${ERROR_REPORTING_MAIL}?cc=${ERROR_REPORTING_CC}&subject=Error%20Report%20with%20ID%20${id}&body=Hallo,%0Aes%20ist%20zu%20einem%20Problem%20bei%20der%20Bearbeitung%20eines%20Requests%20gekommen.%0ADie%20Error%20ID%20lautet:%20${id}.%0AIch%20bitte%20um%20weitere%20Hilfe.`;
  }
}
